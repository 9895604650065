import * as React from "react"
import { ThemeCss } from "gatsby-interface"

const rootCss: ThemeCss = theme => ({
  display: `inline-block`,
  margin: `0 ${theme.space[2]}`,
  color: theme.colors.grey[30],
  flexShrink: 0,
})

export function Bull() {
  return <span css={rootCss}>&bull;</span>
}
